import React from "react"

interface Props {
  children: React.ReactChildren
}

export const SoundBoard: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="soundboard">
      <div className="soundboard-buttons">{children}</div>
    </div>
  )
}
