import React, { useEffect, useRef, useState } from "react"

import "./SoundControl.css"

interface Props {
  src: string
}

export const SoundControlV2: React.FunctionComponent<Props> = ({ src }) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const start = () => {
    audioRef.current.play()
    setIsPlaying(true)
  }
  const stop = () => {
    const element = audioRef.current
    element.pause()
    element.currentTime = 0
    setIsPlaying(false)
  }

  const handleClick = async () => {
    const element = audioRef.current

    if (!isPlaying) {
      start()
    } else {
      stop()
    }
  }

  useEffect(() => {
    const element = audioRef.current
    element.addEventListener("ended", stop)

    return () => {
      element.removeEventListener("ended", stop)
    }
  }, [])

  return (
    <button className="soundcontrol" onClick={handleClick}>
      <div className="soundcontrol-content">
        Click me
        <audio src={src} ref={audioRef} />
      </div>

      <div
        className={`soundcontrol-progress ${isPlaying ? "playing" : ""}`}
        style={{
          transitionDuration: `${
            !isPlaying ? 0 : audioRef.current?.duration ?? 1
          }s`,
        }}
      />
    </button>
  )
}
