import React, { useCallback, useEffect, useState } from "react"

import "./Settings.css"

interface Props {
  onChange: () => SoundBoardSettings
}

export interface SoundBoardSettings {
  singleButton: boolean
  volume: number
}

export const Settings: React.FunctionComponent<Props> = ({ onChange }) => {
  const [settingsState, setSettingsState] = useState<SoundBoardSettings>({
    singleButton: true,
    volume: 5,
  })

  const handleChange = useCallback(
    e => {
      let value = null
      switch (e.target.name) {
        case "singleButton":
          value = e.target.checked
          break
        case "volume":
          value = parseInt(e.target.value, 10)
          break
      }

      const newState = {
        ...settingsState,
        [e.target.name]: value,
      }

      setSettingsState(newState)
      onChange?.(newState)
    },
    [settingsState, setSettingsState]
  )

  return (
    <div className="settings">
      <h2>Settings</h2>
      <div className="settings-playback">
        <label htmlFor="single">One at a time</label>
        <input
          id="single"
          name="singleButton"
          type="checkbox"
          checked={settingsState.singleButton}
          onChange={handleChange}
        />
      </div>
      <div className="settings-volume">
        <label htmlFor="volume">Volume</label>
        <input
          id="volume"
          name="volume"
          type="range"
          min={0}
          max={10}
          value={settingsState.volume}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
