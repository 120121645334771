import React, { useCallback, useEffect, useRef, useState } from "react"

import "./SoundControl.css"
import { STOP, stopAll } from "./events"

interface Props {
  src: string
  volume: number
  stopOthers: boolean
  children: React.ReactChildren
}

export const SoundControlV3: React.FunctionComponent<Props> = ({
  src,
  volume = 5,
  stopOthers = false,
  children,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const start = () => {
    audioRef.current.play()
    setIsPlaying(true)
  }
  const stop = () => {
    const element = audioRef.current
    element.pause()
    element.currentTime = -1
    setIsPlaying(false)
  }

  const handleClick = useCallback(() => {
    const el = audioRef.current
    if (!el) {
      return
    }

    if (!isPlaying) {
      if (stopOthers) {
        stopAll(audioRef.current)
      }
      el.play()
    } else {
      stop()
    }
  }, [isPlaying, stop])

  useEffect(() => {
    const element = audioRef.current
    element.addEventListener("ended", stop)

    return () => {
      element.removeEventListener("ended", stop)
    }
  }, [])

  useEffect(() => {
    const el = audioRef.current
    if (!el) {
      return
    }

    el.addEventListener("play", () => setIsPlaying(true))
    el.addEventListener("pause", () => setIsPlaying(false))
    el.addEventListener("ended", () => setIsPlaying(false))

    const keyUpListener = (e: any) => {
      if (e.keyCode === 27) {
        stop()
      }
    }
    const stopAllListeners = (e: any) => {
      if (e.detail !== audioRef.current) {
        stop()
      }
    }
    document.addEventListener("keyup", keyUpListener)
    document.addEventListener(STOP, stopAllListeners)

    return () => {
      document.removeEventListener("keyup", keyUpListener)
      document.removeEventListener(STOP, stopAllListeners)
    }
  }, [stop])

  useEffect(() => {
    const el = audioRef.current
    if (!el) {
      return
    }
    el.volume = Math.min(1, volume / 10)
  }, [volume])

  return (
    <button className="soundcontrol" onClick={handleClick}>
      <div className="soundcontrol-content">
        {children}
        <audio src={src} ref={audioRef} />
      </div>

      <div
        className={`soundcontrol-progress ${isPlaying ? "playing" : ""}`}
        style={{
          transitionDuration: `${
            !isPlaying ? 0 : audioRef.current?.duration ?? 1
          }s`,
        }}
      />
    </button>
  )
}
