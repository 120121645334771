import { SoundBoard } from "../../../../src/components/Posts/ReactSoundBoard/SoundBoard";
import { SoundBoardV2 } from "../../../../src/components/Posts/ReactSoundBoard/SoundBoardV2";
import { SoundControlV2 } from "../../../../src/components/Posts/ReactSoundBoard/SoundControlV2";
import { SoundControlV3 } from "../../../../src/components/Posts/ReactSoundBoard/SoundControlV3";
import * as React from 'react';
export default {
  SoundBoard,
  SoundBoardV2,
  SoundControlV2,
  SoundControlV3,
  React
};