import React, { useState } from "react"
import { Settings, SoundBoardSettings } from "./Settings/Settings"
import { SoundControlV3 } from "./SoundControlV3"

interface Props {
  children: React.ReactChildren
}

interface Sound {
  id: number
  name: string
  src: string
}

const SOUNDS: Sound[] = [
  {
    id: 1,
    name: "Duck",
    src: "/react-soundboard/farm/duck.mp3",
  },
  {
    id: 2,
    name: "Donkey",
    src: "/react-soundboard/farm/donkey.mp3",
  },
  {
    id: 3,
    name: "Sheep",
    src: "/react-soundboard/farm/sheep.mp3",
  },
  {
    id: 4,
    name: "Rooster",
    src: "/react-soundboard/farm/rooster.mp3",
  },
  {
    id: 5,
    name: "Cow",
    src: "/react-soundboard/farm/cow.mp3",
  },
]

export const SoundBoardV2: React.FunctionComponent<Props> = ({ children }) => {
  const [settings, setSettings] = useState<SoundBoardSettings>({
    volume: 5,
    singleButton: true,
  })

  return (
    <div className="soundboard">
      <Settings onChange={newState => setSettings(newState)} />
      <div className="soundboard-buttons">
        {SOUNDS.map(s => (
          <SoundControlV3
            key={s.id}
            src={s.src}
            volume={settings.volume}
            stopOthers={settings.singleButton}
          >
            {s.name}
          </SoundControlV3>
        ))}
      </div>
    </div>
  )
}
